/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "app/theme/styles/spinner";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '~swiper/swiper-bundle.min.css';
@import '~snazzy-info-window/dist/snazzy-info-window.css'; 
@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
@import "app/theme/styles/theme";
@import "app/theme/styles/rtl";
html {
    scroll-behavior: smooth;
  }
  .contcatusdiv1{
    text-align: center;
}
.contcatusdiv2{
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
}
.ourservicesdiv{
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
}
.ourservicesdiv1{
  flex: 1 1 25%;
    box-sizing: border-box;
    max-width: 25%;
}
.aboutusdiv{
  flex-flow: row wrap; box-sizing: border-box; display: flex;
}
.aboutusdiv1{
  flex: 1 1 50%; box-sizing: border-box; max-width: 50%;
}
.aboutusdiv2{
  flex-direction: row; 
  box-sizing: border-box;
   display: flex;
    place-content: center flex-start;
     align-items: center;
}
.longtextwrap{
    height:72px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.addthis{
  display: none;
}
.sharebtn:hover .addthis{
  display: block;
  width: 35px;
  background-color: #f5f2f2;
}
.at-share-btn-elements{
  display: flex;
    flex-direction: column;
    width: 35px;    
}
.at-share-btn-elements .at-icon-wrapper{
  width: 20px !important;
  height:20px !important;
}

.at-share-btn-elements .at-icon-wrapper .at-icon{
  width: 20px !important;
  height: 20px !important;
}
.listaddthis{
  position: absolute;
  bottom: 0px;
}
.getintouchdiv1{
  flex-flow: row wrap;
   box-sizing: border-box;
    display: flex;
     place-content: center space-between; 
     align-items: center;
}
.getintouchdiv2{
  flex-direction: column; 
  box-sizing: border-box; 
  display: flex;
}
.getitTouchdiv3{
  flex-direction: row;
   box-sizing: border-box;
    display: flex; place-content: center flex-start; align-items: center;
}
.articlep{
  margin-bottom:15px;
}
.testimonials-carousel .swiper-pagination-bullet{
  width: 13px;
  height: 13px;
}
.testimonials-carousel .swiper-pagination-bullet-active{
  width: 15px;
  height: 15px;
}
.testimonials-box{
  background-color: #f5f5f5;
  padding: 20px;
  margin: 0 20px !important;   
     box-shadow: 0 2px 7px 0 rgba(0,0,0,.17);
   
}
@media (max-width:767px){
  .ourservicesdiv1 {
    flex: 1 1 100%;
    max-width: 100%;
}
}
/* Social Icons Css*/
#share-container span {
  display: block;
  margin: 3rem auto;
  font-size: 30px;
  padding: 1rem;
  margin: 1rem;
}
#share-buttons i {
  font-size: 30px;
  margin: 20px;
}
.app.grey .mat-list-base .mat-list-item{
  margin-bottom: 30px !important;
}
.facebook {
      color: #3b5998;
}
.twitter {
    color: #55acee;
}
.linkedin {
    color: #0077b5;
}
.reddit {
    color: #cb2027;
}
.whatsapp {
    color: #25D366;
}
.telegram {
    color: #229ED9;
}
.facebook, .twitter, .linkedin, .reddit, .whatsapp, .telegram {
  opacity: 0.6;
}
.facebook:hover, .twitter:hover, .linkedin:hover, .reddit:hover, .whatsapp:hover, .telegram:hover {
  opacity: 0.9;
}
#share-buttons{
  text-align:center;
  //background: rgb(0, 0, 0, .06);
}
#share-buttons.comments{
  padding-left:100px !important;
}
.showicon{
  display: none;
}
.sharebtn .mat-button-focus-overlay{
  opacity: 0.04 !important;
}
